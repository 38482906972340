body {
  margin: 0;
  font-family: "Google Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  background-image: url("../public/images/bkg-1.jpeg"); /* Substitua pelo caminho da sua imagem */
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  color: #045247; /* Cor do texto, caso desejado */
  font-weight: 600;
  padding-bottom: 20px;
}
